<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine the effect of adding each of the following compounds on the molar solubility,
        <stemble-latex content="$\text{S,}$" />
        of
        <stemble-latex content="$\ce{CaF2}$" />:
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 14px">
              <stemble-latex content="$\textbf{Compound}$" />
            </th>
            <th v-for="heading in headings" :key="heading" style="font-size: 14px">
              <stemble-latex :content="`$\\textbf{${heading}}$`" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(compound, i) in compounds" :key="compound">
            <td>
              <stemble-latex :content="`$\\ce{${compound}}$`" />
            </td>
            <td v-for="value in values" :key="compound + value" style="text-align: center">
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio :key="value" :value="value" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question311b',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      headings: ['Increase', 'Decrease', 'No change'],
      values: ['increase', 'decrease', 'noChange'],
      compounds: ['a) H2SO4', 'b) KOH', 'c) HNO3', 'd) NaNO3'],
    };
  },
};
</script>
